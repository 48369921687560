<script>
import EmailValidator from '@services/EmailValidator'
import { castArray, isObject, keys, last, map, noop, pick, pullAll, union } from 'lodash'
import { formValidators } from '@helpers/ValidationHelper'
import { getTranslationsFrom } from '@helpers/TranslationHelper'
import { isBlank } from '@helpers/ObjectHelper'

function useComponent (component, attrs = null) {
  return {
    component,
    attrs,
  }
}

// Public: Provides a consistent API to simplify defining inputs, making it easy
// to use different input types.
//
// - Allows <Form> to perform advanced config automation.
// - Smart enough to receive options from <Form> in a reactive way.
export default {
  name: 'FormInput',
  inheritAttrs: false,
  props: {
    // Public: The name of the field, which will be camel-cased to obtain the
    // value from the <Form> model.
    field: { type: String, required: true },

    // Public: Determines which component should be rendered. See componentForInput.
    type: { type: [String, Object], default: null },

    // Public: Provided by Form, allows to hide errors until the form is submitted.
    displayErrors: { type: Boolean, default: false },

    // Internal: Provided by Form, inline errors to be displayed by the input.
    serverErrors: { type: Array, default: null },

    // Internal: Provided by Form, allows to customize the labels and hints.
    translationOptions: { type: Object, default: () => ({}) },

    // Internal: Provided by Form, the reactive value that governs the input.
    value: { }, // eslint-disable-line

    // Public: Specifies if the field prop shouldn't be transformed to camel case.
    keepCase: { type: Boolean, default: false },
  },
  data () {
    const { component, attrs } = this.componentForInput(this.type)

    return {
      componentToRender: component,
      attributeOverrides: attrs,
      customInputErrors: null,
    }
  },
  computed: {
    // Internal: Returns label, placeholder, and hint for the FormInput.
    translations () {
      const fieldPropFields = this.field.split('.')
      const field = last(fieldPropFields)
      return getTranslationsFrom(field, this.$attrs, this.translationOptions)
    },
    // Public: Error for the input to display.
    error () {
      return this.displayErrors && !this.valid ? this.allErrors.join(', ') : false
    },
    // Public: An input is valid when it fulfills all validations (if any).
    valid () {
      return this.allErrors.length === 0
    },
    // Public: Returns a list of errors for the input.
    allErrors () {
      return union(this.serverErrors, this.validationErrors).filter(x => x)
    },
    // Public: Gets the validator names depending on the input type
    validatorsForInputType () {
      const allKeys = keys({ ...this.attributeOverrides, ...this.$attrs })
      // dates 'min' and 'max' attrs refer to the allowed dates within the input
      if (this.type === 'date') return pullAll(allKeys, ['min', 'max'])
      return allKeys
    },
    // Internal: Returns validators for the validations used in the input.
    inputValidators () {
      return pick(formValidators, this.validatorsForInputType)
    },
    // Internal: Returns validation errors for the input, or null if none.
    validationErrors () {
      const errors = map(this.inputValidators, (validator, validationAttr) => {
        const validationConfig = this.getSetting(validationAttr)
        if (validator.isValueValid(this.value, validationConfig)) return
        return validator.errorMessage(this.value, validationConfig)
      }).filter(x => x)
      const allValidationErrors = union(errors, this.customInputErrors)
      return allValidationErrors.length > 0 ? allValidationErrors : null
    },
    // Internal: Returns props and attributes to bind to the input component.
    formInputAttrs () {
      return {
        ...this.attributeOverrides,
        ...this.$attrs,
        ...this.translations,
        error: this.error,
        value: this.value,
        'data-field': this.field,
        'data-model': this.translationOptions.prefix,
        'data-type': isObject(this.type) ? this.type.name : this.type,
      }
    },
    // Internal: Avoid the native 'change' event from being propagated.
    formInputListeners () {
      return {
        ...this.$listeners,
        change: noop,
      }
    },
  },
  watch: {
    // Internal: Propagates errors to Form, so that it can keep track of whether
    // this FormInput is valid or not.
    validationErrors (errors) {
      errors ? this.propagateErrors(errors) : this.propagateValid()
    },
    type (type) {
      const { component, attrs } = this.componentForInput(type)
      this.componentToRender = component
      this.attributeOverrides = attrs
    },
    // Internal: Watch the value property and notify any `change` listeners when appropriate
    value (value, oldValue) {
      this.$emit('change', value, oldValue, { valid: this.valid })
    },
  },
  mounted () {
    // Internal: Only propagates errors to the Form, inputs are valid by default.
    this.propagateErrors(this.validationErrors)
  },
  // Internal: When an input is removed, we must clear the invalid state from
  // the form, or the error will never be cleared, and submit will be prevented.
  beforeDestroy () {
    this.propagateValid()
  },
  methods: {
    // Internal: Notifies that the input has new errors (to Form).
    propagateErrors (errors) {
      if (errors) this.$emit('input:errors', this.field, errors)
    },
    // Internal: Notifies that the input has no errors (to Form).
    propagateValid () {
      this.$emit('input:valid', this.field)
    },
    // Internal: Helps to simplify form definitions, by providing a set of types
    // as convenient aliases for input configuration.
    componentForInput (type) {
      if (isObject(type)) {
        return useComponent(type)
      }

      switch (type) {
        case 'boolean': return useComponent(
          (this.hasSetting('items'))
            ? 'Checkboxes'
            : 'Checkbox'
        )
        case 'booleanSlider': return useComponent('BooleanSlider')
        case 'color': return useComponent('ColorInput')
        case 'text': return useComponent('TextInput')
        case 'sms': return useComponent('SmsInput')
        case 'materialText': return useComponent('MaterialTextInput')
        case 'materialCode':
          return useComponent('MaterialTextInput', {
            type: 'tel',
            pattern: '[0-9]*',
            mask: '999999',
          })
        case 'materialPhone':
          return useComponent('MaterialTextInput', {
            type: 'tel',
            pattern: '[0-9]*',
            mask: '(999) 999-9999',
          })
        case 'date':
        case 'date_range':
        case 'date_time':
        case 'time':
          if (this.hasSetting('multi')) {
            if (type !== 'date') throw new Error(`Please add support for ${type} in MultiInput, or use single selection.`)
            return useComponent('MultiInput', { type })
          }
          return useComponent('DateTimeInput', { type })
        case 'number':
          if (this.hasSetting('multi')) {
            return useComponent('MultiInput', {
              type: 'number',
            })
          }
          return useComponent('NumberInput')
        case 'phone':
          if (this.hasSetting('multi')) {
            return useComponent('MultiInput', {
              type: 'phone',
            })
          }
          return useComponent('PhoneInput')
        case 'file': return useComponent('FileInput')
        case 'audio': return useComponent('AudioInput')
        case 'image': return useComponent('ImageInput')
        case 'password':
          return useComponent('TextInput', { password: true })
        case 'radio':
          return useComponent('RadioButtons', (!this.$attrs.name) ? { name: this.field } : null)
        case 'textarea':
          return useComponent('TextInput', { multiline: true })
        case 'email':
          return useComponent('TextInput', { validate: EmailValidator })
        case 'emails':
          return useComponent('EmailsInput')
        case 'select':
          return useComponent(
            this.hasSetting('fetchOptions')
              ? 'RemoteSelectInput'
              : 'SelectInput'
          )
        case 'buttonGroup':
          return useComponent(
            this.hasSetting('customValueType')
              ? 'ButtonGroupSelectInputWithCustomValue'
              : 'ButtonGroupSelectInput'
          )
        case 'boxGroup':
          return useComponent('BoxGroupSelectInput')
        case 'chips':
          return useComponent('SelectInput', {
            multi: true,
            allowCreate: true,
          })
        default:
          if (this.hasSetting('fetchOptions')) return useComponent('RemoteSelectInput')
          if (this.hasSetting('items')) return useComponent('SelectInput')
          return useComponent('TextInput')
      }
    },
    getSetting (name) {
      if (this.$attrs.hasOwnProperty(name)) return this.$attrs[name]
      else if (this.attributeOverrides && this.attributeOverrides.hasOwnProperty(name)) return this.attributeOverrides[name]
      else return undefined
    },
    hasSetting (name) {
      return this.$attrs.hasOwnProperty(name) || (Boolean(this.attributeOverrides) && this.attributeOverrides.hasOwnProperty(name))
    },
    onCustomInputErrors (errors) {
      this.customInputErrors = isBlank(errors) ? null : castArray(errors)
    },
  },
}
</script>

<template>
  <component
    :is="componentToRender"
    v-bind="formInputAttrs"
    class="form-control"
    :class="{ 'has-errors': error }"
    v-on="formInputListeners"
    @input:customErrors="onCustomInputErrors"
  >
    <template v-for="(content, slot) in $scopedSlots" :slot="slot" slot-scope="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
    <template v-for="(content, slot) in $slots" :slot="slot">
      <slot :name="slot"/>
    </template>
  </component>
</template>

<style lang="scss" scoped>
.form-control {
  margin-bottom: 32px;
}
</style>
