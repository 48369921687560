<script>
import InputMixin from '@mixins/InputMixin'

export default {
  name: 'NumberInput',
  mixins: [
    InputMixin,
  ],
  props: {
    // Public: Minimum value allowed for the input.
    min: { type: [String, Number], default: null },

    // Public: Maximum value allowed for the input.
    max: { type: [String, Number], default: null },

    // Public: How much the value increases when the up/down arrows are pressed.
    step: { type: [String, Number], default: 1 },

    // Public: Content of the input.
    value: { type: [String, Number], default: null },
  },
  computed: {
    inputListeners () {
      return {
        ...this.$listeners,
        input: event => {
          const value = event.target.value
          const parsedValue = parseFloat(value)
          if (isNaN(parsedValue)) {
            this.$emit('input', value)
          } else {
            this.$emit('input', parsedValue)
          }
        },
      }
    },
  },
}
</script>

<template>
  <div class="number-input">
    <Label v-if="label" v-bind="{ required, hint }">{{ label }}</Label>
    <input
      :class="{ error }"
      :disabled="disabled"
      :max="max"
      :min="min"
      :placeholder="placeholder"
      :step="step"
      :value="value"
      type="number"
      class="input"
      v-on="inputListeners"
    >
    <InputError v-if="error" :error="error"/>
  </div>
</template>

<style lang="scss" scoped>
.number-input {
  position: relative;
}

@include input;
</style>
