<script>
import EditedBadge from '@components/EditedBadge'
import NavigationPanel from '@components/NavigationPanel'
import SidebarStore from '@stores/SidebarStore'
import { cloneDeep, snakeCase, throttle } from 'lodash'
import { labelForOption } from '@helpers/OptionHelper'
import { scrollToTop } from '@helpers/ScrollHelper'
import { watchUntil } from '@helpers/WatchHelper'

// Internal: An arbitrary amount of a section from the top in order for us to
// consider it as the highlighted one.
const visibleSectionThreshold = 140

// Internal: The space between the title section and the content.
const titleSectionBottomMargin = 16

export default {
  name: 'SettingsLayout',
  components: {
    NavigationPanel,
    EditedBadge,
  },
  inheritAttrs: false,
  props: {
    // Public: A list of available groups to display in the sidebar.
    // NOTE: Groups are identified by the id property.
    sidebarGroups: { type: Array, default: () => [] },

    // Public: A bag of props that is made available to all group components.
    contentProps: { type: Object, default: () => ({}) },
  },
  data () {
    const allGroups = cloneDeep(this.sidebarGroups)
    allGroups.forEach(group => {
      group.edited = false
      group.label = labelForOption(group)
    })

    return {
      // Internal: An approximate initial height, adjusted by resize callback.
      titleSectionHeight: 93,

      // Internal: We want groups to stay expanded as they get reordered.
      animatingGroups: false,

      // Internal: The available groups in the sidebar.
      allGroups,

      // Internal: The currently selected group, which is expanded.
      selectedGroup: this.sidebarItemFromRoute(allGroups) || allGroups[0],
    }
  },
  computed: {
    ...SidebarStore.mapGetters('currentSections', 'activeSection'),
    groups () {
      return this.allGroups.filter(group => this.hasPermission(group.permission))
    },
  },
  watch: {
    selectedGroup: {
      handler (group) {
        SidebarStore.setCurrentGroupId(group.id)
      },
      immediate: true,
    },
  },
  mounted () {
    this.attachWindowListener('scroll', throttle(this.checkSectionInViewport, 300, { leading: false }))

    // Internal: Allow to deep-link to a section.
    const sectionTitle = this.$route.query.section
    if (sectionTitle) this.scrollToSectionFromRoute(sectionTitle)
  },
  methods: {
    // Internal: Return the sidebar group that was supplied in the URL as parameter, if any
    sidebarItemFromRoute (groups) {
      const id = this.$route.query.sidebar
      return id && groups.find(group => group.id === id)
    },
    // Internal: If a section was specified in route params, it scrolls to it if
    // found in the sidebar sections.
    scrollToSectionFromRoute (sectionTitle) {
      watchUntil(this, 'currentSections',
        sections => sections.find(section => snakeCase(section.title) === sectionTitle),
        section => setTimeout(() => this.selectSection(section), 50),
      )
    },
    // Internal: Sets the group as selected, and adds a delay to move it to the top.
    switchToGroup (group) {
      scrollToTop(this.$el)
      this.selectedGroup = group
      this.$emit('group:change', group)
    },
    // Internal: Checks which section is predominantly being displayed in the
    // viewport, and marks it as the active one.
    checkSectionInViewport () {
      SidebarStore.notifyScroll({ topThreshold: this.titleSectionHeight + visibleSectionThreshold })
    },
    // Internal: Scrolls to the selected section, we need to add an offset
    // because the title section is sticky.
    selectSection (section) {
      SidebarStore.selectSection({ section, scrollOptions: { animate: true, offset: -this.titleSectionHeight } })
    },
    // Internal: Replace the content with the component for the specified group.
    selectGroup (group) {
      if (group !== this.selectedGroup && this.selectedGroup.edited) {
        this.modalConfirm({
          message: `You have unsaved changes in ${this.selectedGroup.label}.`,
          confirmLabel: 'Continue without saving',
          confirm: () => this.switchToGroup(group),
        })
      } else {
        this.switchToGroup(group)
      }
    },
    // Internal: Keep track of the title section height to position the sidebar.
    onTitleResize ({ contentRect }) {
      this.titleSectionHeight = contentRect.height + titleSectionBottomMargin
    },
  },
}
</script>

<template>
  <CardLayout class="settings-layout" limitWidth v-bind="$attrs" @layout:titleResize="onTitleResize">
    <template #title>
      <slot name="title"><TitleSection slot="title" backBtn/></slot>
    </template>
    <template #solo>
      <div class="settings-container">
        <div class="sidebar">
          <div class="sidebar-groups" :style="`top: ${titleSectionHeight}px`">
            <transition-group name="panels-reorder" @before-enter="animatingGroups = true" @leave="animatingGroups = false">
              <NavigationPanel
                v-for="group in groups"
                :key="group.id"
                :label="group.label"
                :class="{ disabled: group.disabled }"
                :collapsed="animatingGroups || group !== selectedGroup || currentSections.length === 0"
                collapsible
                class="sidebar-group"
                :title="group.label"
                @panel:headerClick="selectGroup(group)"
              >
                <template slot="title">
                  <ActionLink :label="group.label" class="sidebar-group__label" :class="{ active: group === selectedGroup }"/>
                  <EditedBadge v-if="group.edited" compact/>
                </template>
                <template v-if="group === selectedGroup">
                  <ActionLink v-for="section in currentSections" :key="section.title" :class="{ active: section === activeSection }" class="sidebar-section" @click="selectSection(section)">{{ section.title }}</ActionLink>
                </template>
              </NavigationPanel>
            </transition-group>
          </div>
        </div>
        <Card class="settings-content">
          <slot name="content" :selectedGroup="selectedGroup">
            <keep-alive>
              <component :is="selectedGroup.component" :key="selectedGroup.id" v-bind="contentProps" @form:edited="selectedGroup.edited = $event" v-on="$listeners"/>
            </keep-alive>
          </slot>
        </Card>
      </div>
    </template>
  </CardLayout>
</template>

<style lang="scss" scoped>
@include media(desktop) {
  .settings-container {
    margin: 0 $padding-horizontal-html 0;
  }
}

@include media(tablet) {
  // NOTE: IE11 ignores flex-wrap unless the element has a set width, so select
  // inputs with many tags wouldn't wrap as expected.
  @include ie11 {
    .settings-content {
      width: 75%;
    }
  }

  .settings-container {
    @include flex-row;
  }

  .sidebar {
    margin-right: 24px;
  }
}

.settings-layout ::v-deep {
  .layout__title {
    // Needs to be displayed on top of elements that appear later in the DOM.
    @include z-index(sticky-title-section);

    position: sticky;
    top: -1px;
  }

  .sidebar-groups {
    position: sticky;
  }
}

.sidebar {
  display: flex;
  flex: 0 0 25%;
  flex-direction: column;
}

.settings-content {
  @include flex-column;

  // NOTE: IE11 does not support sticky, so we don't need extra space to allow
  // the last section to scroll all the way up when clicking the sidebar.
  @include ie11 {
    margin-bottom: 32px;
  }

  margin-bottom: 360px;
  padding: 32px 56px;

  & ::v-deep .form-actions.sticky {
    margin: 0 -32px -32px;
  }
}

.sidebar-group {
  .edited-badge {
    margin-left: 8px;
    vertical-align: 2px;
  }

  &.expanded ::v-deep .panel-actions {
    display: none;
  }

  & ::v-deep .panel-header {
    @include clickable;
  }

  & ::v-deep .panel-content {
    padding: 8px;
  }

  &.disabled {
    cursor: not-allowed;
    // We can't set pointer-events: none in the panel directly, since the cursor pointer will be ignored;
    // https://stackoverflow.com/questions/25654413/add-css-cursor-property-when-using-pointer-events-none
    & ::v-deep * {
      pointer-events: none;
    }
  }
}

.panels-reorder-move {
  transition: transform 0.5s;
}
</style>
