<script>
import Draggable from '@components/Draggable'
import InputMixin from '@mixins/InputMixin'
import KeyboardNavigationService from '@services/KeyboardNavigationService'
import { difference, isEmpty, isEqual, isPlainObject, last, take, trim, unionWith, uniq } from 'lodash'
import { filterItems } from '@helpers/FilterHelper'
import { ifEverChanged } from '@helpers/WatchHelper'
import { isPresent } from '@helpers/ObjectHelper'
import { removeBy } from '@helpers/ArrayHelper'

// Internal: In order to prevent performance issues, since we expect users to
// search rather than scroll on very long lists, we don't render all of the items.
const MAX_RENDERED_ITEMS = 200

export default {
  name: 'SelectInput',
  components: {
    Draggable,
  },
  mixins: [
    InputMixin,
  ],
  props: {
    // Public: Displayed when no items are available in `allowCreate`-mode.
    createItemMessage: { type: String, default: 'Type to create a new item…' },

    // Public: Displayed when no items are available in the dropdown.
    emptyItemsMessage: { type: String, default: 'No items available' },

    // Public: Items that can be selected and will be displayed in the dropdown.
    items: { type: Array, default: null },

    // Public: A title to display over all items
    itemsTitle: { type: String, default: '' },

    // Public: Whether the input should allow to create new items.
    allowCreate: { type: Boolean, default: false },

    // Public: Whether the input shows created items as options.
    displayCreatedAsOptions: { type: Boolean, default: true },

    // Public: Whether the input should display an option to unselect an item
    displayUnselect: { type: Boolean, default: true },

    // Public: Whether the input should allow the selection of multiple items.
    //
    // NOTE: Determines if the value propagated by the input event is an Array
    // or a single value.
    multi: { type: Boolean, default: false },

    // Public: The max number of items that can be selected for any one field
    maxSelections: { type: Number, default: Infinity },

    // Public: Selected item or list of selected items that populate the input.
    value: { type: [Object, Array, String, Number], default: null },

    // Public: Item property to use when displaying items in the dropdown.
    labelProp: { type: String, default: 'label' },

    // Public: Item property to use when displaying a badge in the dropdown item
    badgeProp: { type: String, default: null },

    // Public: Function that returns what is searchable from a given item.
    searchableContent: { type: Function,
      default: function (item) {
        if (this.badgeProp) {
          return `${item[this.labelProp]} ${item[this.badgeProp]}`
        } else {
          return item[this.labelProp]
        }
      },
    },

    // Public: Item property to use as the internal item value (and as an id).
    valueProp: { type: String, default: 'value' },

    // Public: Whether the whole items should be propagated on a change event.
    // This also determines whether what is received/stored when using v-model.
    wholeItem: { type: Boolean, default: false },

    // Style: Allows to specify a different style, such as `vertical`.
    // NOTE: Only applies when using the `multi` option.
    chipsStyle: { type: String, default () { return this.itemsAreSortable ? 'vertical' : 'inline' } },

    // Public: For single selection mode, specifies if the dropdown should be
    // collapsed or remain expanded after an item is selected
    collapseOnSelection: { type: Boolean, default: true },

    // Public: Specifies if the filteringBy string should clear out (or not)
    // when an option is selected
    clearFilterOnSelection: { type: Boolean, default: true },

    // Public: Specifies if the input's selected items should be sortable
    itemsAreSortable: { type: Boolean, default: false },
  },
  data () {
    return {
      // Internal: Items that the user might have created in `allowCreate` mode.
      createdItems: [],

      // Internal: Whether the dropdown is open, displaying the available items.
      expanded: false,

      // Internal: Whether the dropdown was ever open.
      ...ifEverChanged(this, { watch: 'expanded', everChanged: 'everExpanded' }),

      // Internal: User input to filter the displayed options.
      filteringBy: '',

      // Internal: The items that are selected.
      selectedItems: [],

      // Internal: Keeps track of the focused item.
      navigationService: new KeyboardNavigationService(this),
    }
  },
  computed: {
    // Internal: The specified items, plus any extra items that the user has
    // created in the allowCreate mode.
    itemsAndCreated () {
      // When using wholeItem, the selected items can be displayed immediately.
      const availableItems = this.items ||
        (this.wholeItem && this.value && (this.multi ? this.value : [this.value])) ||
        []
      return this.allowCreate ? unionWith(this.createdItems, availableItems, isEqual) : availableItems
    },
    // Internal: Filter items according to the current user input.
    // In multi-select mode it doesn't display currently selected items.
    filteredItems () {
      const items = this.multi ? this.itemsAndCreated.filter(item => !this.isItemSelected(item)) : this.itemsAndCreated
      return filterItems(items, { query: this.filteringBy, searchableContent: this.boundSearchableContent })
    },
    // Internal: Returns the items that will be displayed in the dropdown.
    displayedItems () {
      return [
        this.displayNoSelectionItem && this.noSelectionItem,
        this.displayCreateItem && this.createItem,
        ...take(this.filteredItems, MAX_RENDERED_ITEMS),
      ].filter(x => x)
    },
    // Internal: A new item can be created from a non-empty value in the input.
    displayCreateItem () {
      return this.allowCreate &&
        !isEmpty(trim(this.filteringBy)) &&
        !this.itemsAndCreated.find(item =>
          item[this.labelProp] === this.filteringBy || item[this.valueProp] === this.filteringBy)
    },
    // Internal: A blank option to unselect the current item will be displayed.
    displayNoSelectionItem () {
      return this.selectedItem && this.displayUnselect
    },
    // Internal: A fake item that allows to create a new item based on the input.
    createItem () {
      return { [this.labelProp]: `Add ${this.filteringBy}…`, creatable: true }
    },
    // Internal: A fake item that allows to unselect the current item.
    noSelectionItem () {
      return { [this.labelProp]: 'Unselect', unselect: true }
    },
    // Internal: Whether a single item can be selected. Single-selection mode.
    singleSelection () {
      return !this.multi
    },
    // Internal: The selected item in single-selection mode.
    selectedItem () {
      return this.singleSelection && this.selectedItems[0]
    },
    // Internal: Helper function to apply the `wholeItem` setting.
    itemToValue () {
      return this.wholeItem ? item => item : item => item[this.valueProp]
    },
    // Internal: Helper function to extract the inner value from the `value` prop
    // by taking into account the `wholeItem` setting
    extractValue () {
      return this.wholeItem ? item => item[this.valueProp] : value => value
    },
    // Public: Returns the value for the text input.
    // When the input is closed, it displays the selected item label.
    searchInputValue () {
      return this.expanded
        ? this.filteringBy
        : (this.selectedItem && this.selectedItem[this.labelProp]) || ''
    },

    // Internal: Determines where the select input form should be shown or hidden.
    showSelectInput () {
      return this.singleSelection || (this.multi && this.selectedItems.length < this.maxSelections)
    },
    // Public: Indicates if the no items section should be displayed. Occurs if:
    //   - There are no items.
    //   - Displaying "Add…" or "Unselect" and a `noItems` slot was provided.
    shouldDisplayNoItems () {
      return this.displayedItems.length === 0 ||
        ((this.displayCreateItem || this.displayNoSelectionItem) && this.displayedItems.length === 1 && this.$slots.noItems)
    },
    // Internal: Returns true if we are not rendering all the items.
    displayMoreItemsMessage () {
      return this.filteredItems.length > this.displayedItems.length
    },
    boundSearchableContent () {
      return this.searchableContent.bind(this)
    },
  },
  watch: {
    // Internal: Emit the typed message when it changes
    filteringBy (value) {
      this.$emit('input:change', value)
    },
    // Internal: Need to remove items from selectedItems when the value changes.
    value (value) {
      this.updateSelectedState(value)
    },
    // Internal: Needed if items are fetch async
    items () {
      this.updateSelectedState(this.value)
      this.checkForDuplicateItems()
    },
    expanded (isExpanded) {
      if (isExpanded) this.$emit('selectInput:expanded')
    },
  },
  created () {
    // Internal: Need to add items to selectedItems based on the value.
    this.updateSelectedState(this.value)
  },
  beforeMount () {
    this.checkForDuplicateItems()
  },
  methods: {
    // Internal: Report any duplicate items found in this select input
    checkForDuplicateItems () {
      if (this.items) {
        const labels = this.items.map(item => item[this.labelProp])
        const uniqueLabels = uniq(labels)
        if (labels.count !== uniqueLabels.count) {
          this.notifyError(new Error('Duplicate items in select input'), {
            duplicateLabels: difference(labels, uniqueLabels),
            items: this.items,
          })
        }
      }
    },
    // Internal: Synchronizes selectedItems with the specified value.
    updateSelectedState (value) {
      this.selectedItems = isPresent(value) ? this.getSelectedItemsFrom(value) : []
    },
    // Internal: Calculates the selectedItems based on the specified value.
    getSelectedItemsFrom (value) {
      // Convert to Array to process all cases in the same way.
      const values = this.multi ? [...value] : [value]

      // If allowCreate, add all items to createdItems first
      if (this.allowCreate) values.forEach(value => !this.isValueSelectedOrCreated(value) ? this.createNewItem(value) : '')

      // It's more performant to iterate on the values and use find to short-circuit.
      return values.map(value => this.isValueSelectedOrCreated(value)).filter(x => x)
    },
    // Public: Expands a dropdown that displays the available items.
    expandDropdown () {
      if (this.disabled) return
      this.expanded = true
      this.$refs.selectInput.select()
      this.$nextTick(() => this.navigationService.focusPreferredItemOrFirst())
      this.$emit('focus')
    },
    // Public: Closes the dropdown that displays the available items.
    collapseDropdown () {
      this.filteringBy = ''
      this.expanded = false
      this.$emit('blur')
    },
    // Public: Allows to expand or close the menu from the toggle.
    toggleDropdown () {
      this.expanded ? this.collapseDropdown() : this.expandDropdown()
    },
    // Public: Filter the displayed items according to what the user types.
    onFilteringChange (event) {
      this.filteringBy = event.target.value
      this.focusFirstItem()
    },
    // Internal: Focuses the first actual element, unless there are none in
    // which case it tries to focus the item added in `allowCreate` mode.
    focusFirstItem () {
      this.$nextTick(() => this.navigationService.focusFirstItem())
    },
    // Internal: Returns true if the element should not be focused by default.
    shouldSkipFocus (item) {
      if (item.unselect) return this.displayedItems.length > 1
      if (item.creatable) return this.displayedItems.length > (this.displayNoSelectionItem ? 2 : 1)
      return false
    },
    // Public: Selects the specified item and notifies the change.
    selectItem (option) {
      const item = option.creatable ? this.createNewItem(this.filteringBy) : option
      this.multi ? this.selectItemMulti(item) : this.selectItemSingle(item)
      if (this.clearFilterOnSelection) this.filteringBy = ''
      this.notifyValueChange()
      this.focusFirstItem()
    },
    // Internal: In single-select mode the dropdown is closed after selection.
    selectItemSingle (item) {
      this.selectedItems = item.unselect ? [] : [item]
      if (this.collapseOnSelection) this.collapseDropdown()
    },
    // Internal: In multi-select mode the input remains focused after selection.
    selectItemMulti (item) {
      if (!this.findItemForValue(this.selectedItems, item)) this.selectedItems.push(item)
    },
    // Public: Removes a selected item.
    unselectItem (item) {
      const removedItem = removeBy(this.selectedItems, item)
      this.focusFirstItem()
      if (removedItem) this.notifyValueChange()
    },
    // Public: Unselects the last item when the search input is empty.
    onDeletePressed () {
      if (!this.filteringBy) {
        const lastSelectedItem = last(this.selectedItems)
        if (lastSelectedItem) this.unselectItem(lastSelectedItem)
      }
    },
    // Internal: Propagates the input event. The value type is determined by `multi`.
    notifyValueChange () {
      const values = this.selectedItems.map(this.itemToValue)
      const value = isPresent(values[0]) ? values[0] : null
      this.$emit('input', this.multi ? values : value)
    },
    // Internal: Returns true if the item is currently selected.
    isItemSelected (item) {
      return this.findItemForValue(this.selectedItems, item[this.valueProp])
    },
    // Internal: Returns true if the value is currently selected or in the created items list.
    isValueSelectedOrCreated (itemOrValue) {
      return this.findItemForValue(this.itemsAndCreated, this.extractValue(itemOrValue))
    },
    findItemForValue (items, value) {
      return items.find(itemToCheck => isEqual(itemToCheck[this.valueProp], value))
    },
    // Helper: Returns a new item based on the current user input.
    createNewItem (value) {
      const newItem = this.wholeItem && isPlainObject(value) ? value : { [this.labelProp]: value, [this.valueProp]: value }
      if (this.displayCreatedAsOptions) this.createdItems.push(newItem)
      return newItem
    },
  },
}
</script>

<template>
  <div :class="[{ disabled }, multi ? 'multi' : 'single']" class="select-input">
    <BlurArea v-if="expanded" @blur="collapseDropdown"/>

    <slot name="label">
      <Label v-if="label" v-bind="{ required, hint }">{{ label }}</Label>
    </slot>

    <div class="select-input-container" :class="{ expanded }">
      <template v-if="multi">
        <Draggable
          v-if="itemsAreSortable"
          v-model="selectedItems"
          :disabled="!itemsAreSortable || disabled"
          :withHandle="false"
          class="draggable"
          @end="notifyValueChange"
        >
          <SelectInputSelectedItem
            v-for="(item, index) in selectedItems"
            :key="`${item[labelProp]}-${item[valueProp]}`"
            v-bind="{ chipsStyle, labelProp, disabled, item, index }"
            @removeItem="unselectItem"
          >
            <slot name="multiSelectedItem" v-bind="{ item }"/>
          </SelectInputSelectedItem>

        </Draggable>
        <SelectInputSelectedItem
          v-for="item in selectedItems"
          v-else
          :key="`${item[labelProp]}-${item[valueProp]}`"
          v-bind="{ chipsStyle, labelProp, disabled, item }"
          @removeItem="unselectItem"
        >
          <slot name="multiSelectedItem" v-bind="{ item }"/>
        </SelectInputSelectedItem>
      </template>

      <div v-if="showSelectInput" :class="{ 'single-selection': singleSelection }" class="input-wrapper">
        <input
          ref="selectInput"
          v-focused="expanded"
          :class="{ error }"
          :disabled="disabled"
          :placeholder="placeholder"
          :value="searchInputValue"
          type="text"
          class="input"
          @focus="expandDropdown"
          @input="onFilteringChange"
          @keydown.delete="onDeletePressed"
          @keydown.tab="collapseDropdown"
          @keydown.esc.prevent="collapseDropdown"
        >
        <Icon
          v-if="singleSelection"
          :class="error"
          class="select-input-toggle"
          name="downSmall"
          size="medium"
          @click="toggleDropdown"
        />

        <InputError v-if="error" :error="error"/>

        <div v-if="everExpanded" v-show="expanded" class="input-items-dropdown">
          <div v-if="itemsTitle" class="input-items-title-container">
            <span class="input-items-title">{{ itemsTitle }}</span>
          </div>
          <div
            v-for="item in displayedItems"
            :key="item.creatable ? 'creatable' : item.unselect ? 'unselect' : `${item[labelProp]}-${item[valueProp]}`"
            v-navigation-item="{ navigationService, onlyForParentScroll: true, skip: shouldSkipFocus(item), prefer: item === selectedItem }"
            :class="{ creatable: item.creatable, unselect: item.unselect }"
            class="input-item"
            @mousedown="item.disabledSelection ? {} : selectItem(item)"
            @click="item.disabledSelection ? {} : selectItem(item)"
          >
            <slot name="itemLabel" :item="item" :filteringBy="filteringBy">
              <SelectInputItemLabel v-bind="{ item, filteringBy, labelProp, badgeProp }"/>
            </slot>
            <slot name="itemExtras" :item="item"/>
          </div>
          <div v-if="displayMoreItemsMessage" class="input-item custom-message">
            There are more options, type to narrow them down.
          </div>

          <div v-show="shouldDisplayNoItems" class="input-item empty">
            <slot name="noItems">{{ allowCreate ? createItemMessage : emptyItemsMessage }}</slot>
          </div>

          <div v-if="!shouldDisplayNoItems && !displayMoreItemsMessage && $slots.customItemsMessage" class="input-item custom-message">
            <slot name="customItemsMessage"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.select-input {
  position: relative;
}

.input-wrapper {
  @include input;

  flex: auto;
  min-width: fit-content;
  position: relative;

  &.single-selection {
    width: 100%;
  }

  .input {
    overflow: hidden;
    padding: 4px 24px 5px 8px;
  }
}

.select-input-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &.expanded {
    @include z-index(above-blur);
  }
}

.select-input-toggle {
  @include clickable;

  fill: currentColor;
  position: absolute;
  right: 6px;
  top: 6px;

  .input.error + & {
    fill: $error-color;
  }

  .select-input-container.expanded & ::v-deep .icon-svg {
    transform: rotate(180deg);
  }
}

.input-items-dropdown {
  @include bordered-box;

  animation: enter-from-above 0.15s normal forwards ease-out;
  background: $WHITE;
  box-shadow: 0 0 10px 0 $shadow-lighter;
  color: $fc-html;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: auto;
  padding: 4px 1px;
  position: absolute;
  width: 100%;
}

.input-item {
  &.focused {
    background-color: $tundora-l-3;
  }

  &.empty,
  &.custom-message {
    color: $fc-html-light;
    cursor: auto;
    opacity: 0.8;
    padding: 5px 15px 4px;

    &:hover {
      background: none;
    }
  }
}

.input-item-description {
  background-color: auto;
  color: $fc-html-light;
  cursor: pointer;
  font-size: $fs-secondary;
  padding: 0 15px 4px;
}

.input-items-title {
  color: $tundora-l-1;
  font-size: $fs-secondary;
  font-weight: $fw-regular;
  margin-left: 10px;
}

.input-items-title-container {
  margin-bottom: 5px;
}

.draggable {
  width: 100%;

  .selected-item {
    cursor: move;
    justify-content: space-between;

    &.inline {
      margin-right: 0;
    }
  }
}
</style>
