<script>
import InputMixin from '@mixins/InputMixin'
import { toggleBy } from '@helpers/ArrayHelper'

export default {
  name: 'ButtonGroupSelectInput',
  mixins: [
    InputMixin,
  ],
  props: {
    // Public: Items that can be selected and will be displayed as buttons.
    items: { type: Array, required: true },

    // Public: Item property to use when displaying items as button groups.
    labelProp: { type: String, default: 'label' },

    // Public: Selected item that populates the input.
    value: { type: [String, Number, Array, Object], default: null },

    // Public: Item property to use as the internal item value (and input id).
    valueProp: { type: String, default: 'value' },

    // Public: Makes the control single row and stretches the items to full width
    singleRow: { type: Boolean, default: false },

    // Public: Allow multiple selection mode
    multi: { type: Boolean, default: false },

    // Public: Show a `none` option with empty value as first. Works only for single select
    showNone: { type: Boolean, default: false },

    // Public: Option that allows a user to unselect if they press the selected item
    allowUnselect: { type: Boolean, default () { return this.multi } },
  },
  computed: {
    values () {
      return this.value || []
    },
  },
  methods: {
    // Public: Selects or unselects the specified item and emits the change.
    toggleItem (item) {
      if (this.disabled || (this.isSelected(item) && !this.allowUnselect)) return

      const itemValue = item[this.valueProp]

      const newValue = this.multi
        ? toggleBy(this.values, itemValue)
        : (itemValue === this.value ? null : itemValue)

      this.$emit('input', newValue)
    },
    selectNone () {
      if (!this.multi) this.$emit('input', null)
    },
    isSelected (item) {
      if (item === null) return this.value === null
      const itemValue = item[this.valueProp]
      return this.multi ? this.values.includes(itemValue) : this.value === itemValue
    },
  },
}
</script>

<template>
  <div class="button-select" :class="{ 'button-select--single-row': singleRow }">
    <Label v-if="label" v-bind="{ required, hint }">{{ label }}</Label>
    <div class="button-select__input-wrapper">
      <div class="button-select__options">
        <button
          v-if="showNone && !multi"
          :class="{ disabled, selected: isSelected(null) }"
          type="button"
          class="button-select__option"
          @click="selectNone"
        >
          None
        </button>
        <button
          v-for="item in items"
          :key="`${item[labelProp]}-${item[valueProp]}`"
          :class="{ disabled, selected: isSelected(item), 'button-select__option--multi': multi }"
          type="button"
          class="button-select__option"
          @click="toggleItem(item)"
        >
          <Checkbox v-if="multi" class="button-select__option__checkbox" light :value="isSelected(item)"/>
          {{ item[labelProp] }}
        </button>
        <slot/>
      </div>
    </div>
    <InputError v-if="error" :error="error"/>
  </div>
</template>

<style lang="scss" scoped>
.button-select__options {
  display: flex;
  flex-wrap: wrap;
}

.button-select--single-row .button-select__options {
  flex-wrap: nowrap;
}

.button-select__option {
  @include clickable;
  @include bordered-box;

  background-color: $WHITE;
  color: $tundora_l-1;
  font-size: $fs-normal;
  line-height: 22px; // line height is reduced by 2px from 24px because of the border added to the element
  margin: 4px 8px 4px 0;
  outline: none;
  padding: 4px 12px;
  position: relative;

  .button-select--single-row & {
    width: 100%;
  }

  &.button-select__option--multi {
    padding-left: 32px;
  }

  .button-select__option__checkbox {
    left: 6px;
    margin-top: 1px;
    pointer-events: none;
    position: absolute;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    border-color: $tundora_l-1;
  }

  &.selected {
    background-color: $cipher-green-l-0;
    border-color: $cipher-green-l-0;
    color: $WHITE;
  }

  &.disabled {
    background-color: $tundora-l-3;
    border-color: $tundora_l-2;
    color: $tundora_l-1;
  }

  &.disabled.selected {
    background-color: $tundora_l-2;
    border-color: $tundora_l-2;
    color: $WHITE;
  }
}
</style>
