<script>
import InputMixin from '@mixins/InputMixin'

export default {
  name: 'SelectInputSelectedItem',
  mixins: [
    InputMixin,
  ],
  inheritAttrs: false,
  props: {
    // Public: Items we are showing
    item: { type: Object, required: true },

    // Public: The index of the item we are showing
    index: { type: Number, default: 0 },

    // Style: Allows to specify a different style, such as `vertical`.
    chipsStyle: { type: String, required: true },

    // Public: Item property to use when displaying items in the dropdown.
    labelProp: { type: String, default: 'label' },
  },
}
</script>

<template>
  <div class="selected-item" :data-item-index="index" :class="[chipsStyle, { disabled, error: item.error }]">
    <slot>
      <span>{{ item[labelProp] }}</span>
    </slot>
    <Button
      v-if="!disabled"
      icon
      compact
      name="Remove"
      class="selected-item-remove"
      @click="$emit('removeItem', item)"
    />
  </div>
</template>

<style lang="scss" scoped>
.selected-item {
  animation: fade 0.2s normal forwards ease-out;
  background-color: $bg-tag;
  border: $br-tag;
  border-radius: $radius-normal;
  color: $fc-html;
  display: flex;
  margin-bottom: 8px; // We need it in case there are too many options and they wrap.
  padding: 4px 2px 5px 8px;

  .selected-item-remove {
    align-self: center;
    color: $tundora-l-1;
    cursor: pointer;
    margin-left: 2px;
    transition: all 0.3s ease;
  }

  &:hover {
    .selected-item-remove {
      color: $fc-html;
    }
  }

  &.error {
    border-color: $input-error-color;
    color: $input-error-color;

    & .selected-item-remove,
    &:hover .selected-item-remove {
      color: $input-error-color;
    }
  }

  &.inline {
    margin-right: 8px;
  }

  &.vertical {
    justify-content: space-between;
    width: 100%;
  }

  &.disabled {
    padding-right: 8px;
  }
}
</style>
