<script>
import DateTimePickerControl from '@components/DateTimePickerControl'
import InputMixin from '@mixins/InputMixin'

export default {
  name: 'DateTimeInput',
  components: {
    DateTimePickerControl,
  },
  mixins: [
    InputMixin,
  ],
  props: {
    ...DateTimePickerControl.props,

    // Public: Whether not to display the clear button.
    hideClearButton: { type: Boolean, default: false },
  },
  watch: {
    // Internal: Need this to make flatpickr assign an error class into the visible form-control
    error (hasError) {
      const picker = this.$el.querySelector('.flatpickr-input.form-control')
      if (picker) picker.classList.toggle('error', hasError)
    },
  },
  methods: {
    // Internal: Clears the current value.
    resetDate () {
      this.$emit('input', null)
    },
  },
}
</script>

<template>
  <div class="datetime-input">
    <Label v-if="label" :required="required" :hint="hint">{{ label }}</Label>
    <div class="input-wrapper">
      <DateTimePickerControl
        v-bind="$props"
        v-on="$listeners"
      />
      <Button
        v-if="!disabled && !hideClearButton && value"
        icon
        compact
        class="clear-button"
        name="Remove"
        @click="resetDate"
      />
    </div>
    <InputError v-if="error" :error="error"/>
  </div>
</template>

<style lang="scss" scoped>
.datetime-input {
  position: relative;

  // On mobile, flatpickr uses a native date input and it creates it manually.
  // Because of this, we need to style the input the old way.
  ::v-deep {
    @include input;

    @include media(tablet-small) {
      .input {
        padding-right: 22px;
      }
    }
  }
}

.input-wrapper {
  position: relative;
}

.clear-button {
  // On mobile, a native date input is used. This is why this is not needed.
  @include media(only-phone) {
    display: none;
  }

  position: absolute;
  right: 5px;
  top: 6px;
}
</style>
