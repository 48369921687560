<script>
import Dates from '@constants/Dates'
import InputMixin from '@mixins/InputMixin'
import {
  formatDate,
  parseDateTime,
  parsePlainDate,
  shiftFromLocalToZone,
  shiftTimeZoneToDisplay,
  toISOString,
} from '@helpers/DateHelper'
import { isDate, isEqual, isString, noop } from 'lodash'

/**
 * For documentation on the library we are using please see:
 * - https://github.com/ankurk91/vue-flatpickr-component
 * - https://flatpickr.js.org/
 */
import FlatPickr from 'vue-flatpickr-component'

// NOTE: Flatpickr uses custom tokens instead of adhering to a standard.
// See https://flatpickr.js.org/formatting/
const FlatpickrDateFormats = {
  DATE: 'm/d/Y',
  DATE_ISO: 'Y-m-d',
  DATE_TIME: 'm/d/Y h:i K',
  DATE_TIME_ISO: 'Z',
  TIME: 'G:i K',
  TIME_ISO: 'H:i',
}

// Helper: Returns the format for the specified alias.
function flatpickrFormatForAlias (alias) {
  return FlatpickrDateFormats[String(alias).toUpperCase()] || throw new Error(`Unknown flatpickr format: ${alias}`)
}

// Internal: The available selection modes for the picker control.
const DATE = 'date' // Allows selecting both a date and a time.
const DATE_TIME = 'date_time' // Allows selecting a date (without a time).
const DATE_RANGE = 'date_range' // Allows selecting a time (no date selection).
const TIME = 'time' // Allows selecting a date range.
const PickerModes = new Set([DATE, DATE_TIME, DATE_RANGE, TIME])

function isMode (mode) {
  return function () { return this.type === mode }
}

/*
  Public: Allows selecting a Date, Time, DateTime, or Date Range.

  Flatpickr uses the browser's Date, which by default parses a date using
  the local time zone, which is not what we want in most cases.

  By abstracting these implementation details, users of this component don't
  need to worry about time zones, or parsing logic, and can supply both plain
  Date objects as well as Strings.
*/
export default {
  name: 'DateTimePickerControl',
  components: {
    FlatPickr,
  },
  mixins: [
    InputMixin,
  ],
  inheritAttrs: false,
  props: {
    // Public: Defines the type of value to be selected.
    // Can be `date`, `date_time`, `date_range`, or `time`.
    type: { type: String, required: true, validator: type => PickerModes.has(type) },

    // Public: Minimum date to allow in date picker in ISO 8601 format yyyy-mm-ddThh:mm:ss
    min: { type: [String, Date], default: undefined },

    // Public: Maximum date to allow in date picker in ISO 8601 format yyyy-mm-ddThh:mm:ss
    max: { type: [String, Date], default: undefined },

    // Public: The content of the date in the input. If a String is used, format should be ISO8601
    value: { type: [String, Date, Array], default: null },

    // Public: Adjusts the step for the minute input (incl. scrolling)
    minuteIncrement: { type: Number, default: 5 },

    // Public: Whether date picker should be displayed inline
    inline: { type: Boolean, default: false },

    // Deprecated: Uses am/pm time as the internal value. Example: '04:00 PM'.
    deprecatedTimeFormat: { type: Boolean, default: false },
  },
  computed: {
    dateAndTime: isMode(DATE_TIME),
    dateOnly: isMode(DATE),
    timeOnly: isMode(TIME),
    pickDateRange: isMode(DATE_RANGE),
    // Internal: Whether the value to emit should be a String instead of a Date (or Array of Date).
    useDateStrings () {
      return !this.value || isString(this.value)
    },
    // Internal: Format used for display.
    displayFormat () {
      if (this.pickDateRange) return 'date'
      return this.type
    },
    // Internal: Format used by flatpickr to format the internal value.
    valueFormat () {
      if (this.timeOnly) return this.deprecatedTimeFormat ? 'time' : 'time_iso'
      return this.dateOnly ? 'date_iso' : 'date_time_iso'
    },
    // Internal: Configuration options for flatpickr.
    config () {
      return {
        // Whether to allow selecting a date range, or a single one.
        mode: this.pickDateRange ? 'range' : 'single',

        // How the date will be displayed in the input.
        dateFormat: flatpickrFormatForAlias(this.valueFormat),

        // How the date will be formatted internally.
        altFormat: flatpickrFormatForAlias(this.displayFormat),

        altInput: true,
        enableTime: this.timeOnly || this.dateAndTime,
        noCalendar: this.timeOnly,
        maxDate: this.max,
        minDate: this.min,
        inline: this.inline,
        minuteIncrement: this.minuteIncrement,
        locale: {
          rangeSeparator: Dates.RANGE_SEPARATOR,
        },
      }
    },
    // Internal: The value provided for flatpickr to display as selected.
    // NOTE: Times are shifted based on the provided time zone.
    displayValue () {
      return this.normalizeValue(this.value, { shiftToDisplay: true })
    },
    normalizedValue () {
      return this.normalizeValue(this.value)
    },
    listeners () {
      return {
        ...this.$listeners,
        input: noop,
        'on-change': this.onFlatpickrChange,
        'on-close': this.onFlatpickrClose,
      }
    },
    // Internal: Time Zone in which the date and times should be interpreted in.
    // By default all date times are based on the care provider's time zone.
    timeZone () {
      return this.dateOnly || this.pickDateRange ? 'UTC' : 'careProviderTimeZone'
    },
  },
  methods: {
    formatDate,
    // Internal: In most cases we want to use the care provider's time zone for
    // input, rather than the user's own time zone, so we need to convert the
    // value for flatpickr to display the equivalent value in local time.
    normalizeAndShift (value, { shiftFromLocal, shiftToDisplay } = {}) {
      const date = isDate(value) ? value : this.dateOnly ? parsePlainDate(value) : parseDateTime(value)
      if (shiftFromLocal) return shiftFromLocalToZone(date, this.timeZone)
      if (shiftToDisplay) return shiftTimeZoneToDisplay(date, this.timeZone)
      return date
    },
    // Internal: Normalizes values coming from a JSON request or selected in
    // flatpickr, ensuring that the internal values are uniform.
    normalizeValue (value, options) {
      if (!value) return null
      switch (this.type) {
        case DATE_RANGE: return this.normalizeDateRange(value, options)
        case TIME: return this.normalizeTime(value, options)
        default: return this.normalizeAndShift(value, options)
      }
    },
    // Internal: Parses a date range, such as 2019-01-01..2019-01-02
    normalizeDateRange (value, options) {
      const [from, to] = isString(value) ? value.split(Dates.RANGE_SEPARATOR) : value
      return [from && this.normalizeAndShift(from, options), to && this.normalizeAndShift(to, options)]
    },
    // Internal: For times we use an ISO time string, such as 15:30.
    normalizeTime (value, options) {
      return value
    },
    // Internal: Notifies of a change in the selected date or range.
    notifyChange (value) {
      if (this.useDateStrings && isDate(value)) {
        value = toISOString(value, { dateOnly: this.dateOnly })
      }
      this.$emit('input', value)
    },
    // Internal: Flatpickr emits some duplicates, so we compare manually to
    // propagate an event only if the value has really changed.
    onFlatpickrChange (value, valueStr) {
      if (this.timeOnly) {
        // Use an ISO time string when selecting time-only.
        value = valueStr
      } else if (this.pickDateRange) {
        const [from, to] = value
        // Notify null instead of an array with two nulls.
        if (!from || !to) value = null
        // Avoid notifying changes for ranges until the selection is complete.
        if (from && !to) return
      } else {
        // For single mode, we unwrap the first element in the array.
        value = value[0] || null
      }

      // Since flatpickr works with dates in the browser time zone, we shift to
      // the timeZone specified in the component (by default, care provider's).
      const timeZoneCorrectedValue = this.normalizeValue(value, { shiftFromLocal: true })

      // Compare manually to filter out duplicate events from flatpickr.
      if (!isEqual(timeZoneCorrectedValue, this.value)) this.notifyChange(timeZoneCorrectedValue)
    },
    // Internal: Makes the interaction a bit friendlier when using `timeOnly`.
    // Also, when picking a range, resets the text input if the range is open.
    onFlatpickrClose (values, valueStr, flatpickr) {
      if (valueStr && this.timeOnly) return this.onFlatpickrChange(values, valueStr, flatpickr)
      if ((this.pickDateRange && values.length < 2)) return flatpickr.setDate(this.displayValue)
    },
  },
}
</script>

<template>
  <div>
    <!-- Hidden value rendered just for testing purposes. See DateTimeInputTestHelper -->
    <div class="hidden-input-value">{{ value }}</div>
    <flat-pickr
      v-bind="{ config, placeholder, disabled }"
      :class="{ inline }"
      class="input"
      :value="displayValue"
      v-on="listeners"
    />
  </div>

</template>

<!-- flatpickr styles with our theme overrides -->
<style src="flatpickr/dist/flatpickr.css"></style>
<style src="@style/flatpickr-theme.scss" lang="scss"></style>

<style lang="scss" scoped>
.flatpickr-input.inline {
  display: none;
}

.hidden-input-value {
  display: none;
}
</style>

<style lang="scss">
.flatpickr-calendar {
  &.inline {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;

    .flatpickr-prev-month {
      left: 0;
    }

    .flatpickr-next-month {
      right: 0;
    }
  }
}
</style>
